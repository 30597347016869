module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-remark-images@7.14.0_gatsby-plugin-sharp@5.14.0_gatsby@5.14.1_babel-eslint@10.1._1d943b872f2a095b6efe084ac619fbf6/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":590,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","quality":50,"withWebp":false,"withAvif":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-manifest@5.14.0_gatsby@5.14.1_babel-eslint@10.1.0_eslint@7.32.0__react-do_99886a09b2c5fb8ba532434ed6454b72/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Phpizza","short_name":"Phpizza","start_url":"/","background_color":"#ffffff","theme_color":"#fb7185","theme_color_in_head":false,"display":"minimal-ui","icon":"content/assets/watercolor-ooblets.jpg","legacy":false,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"b115a8e93e3a6f54b91d45d0290ae230"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby@5.14.1_babel-eslint@10.1.0_eslint@7.32.0__react-dom@18.3.1_react@18.3.1__react@1_ed8321d9829a628b1a5c5872b6e3df56/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
